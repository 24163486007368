const ngslides = [
  {
    id: 0,
    leftText:
      "Normal Girl is a game about women trying to fit into society's " +
      'standards of beauty in orer to feel "normal". Normal Girl takes ' +
      "women's insecurities about themselves and attempts to work through " +
      "them in order to immerse players into how women, and people in general, " +
      "see themselves and how they feel pressured to appear a certain way.",
    rightText:
      "Created the entire game. Designed the mechanics, playtested the game, " +
      "made all the cards, and put together the rule book."
  },
  {
    id: 1,
    leftText: "",
    rightText: ""
  },
  {
    id: 2,
    leftText: "",
    rightText: ""
  },
  {
    id: 3,
    leftText: "",
    rightText: ""
  },
  {
    id: 4,
    leftText: "",
    rightText: ""
  },
  {
    id: 5,
    leftText: "",
    rightText: ""
  },
  {
    id: 6,
    leftText: "",
    rightText: ""
  }
];

export default ngslides;
